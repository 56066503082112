import React, { Component } from 'react';
import axios from 'axios';
import plus from "../Assets/plus.png";
import streakMap from "../Assets/StreakMaplogo@2x.png"
import AddActivity from "./AddActivity.component";
import { Link } from 'react-router-dom';
import { API_URL } from '../variables';


const HomePage = props => (
  <Link to="/activity" state={{id:props.Activity._id}}>
    <button className='div5'>
      <div className='div6'>{props.Activity.title}</div>
      <label className='div7'>Streak</label>
      <label className='div7'>Longest</label>
      <div className='div8'>{props.Activity.streak}</div>
      <div className='div8'>{props.Activity.longest}</div>
    </button>
  </Link>
)


export default class ActivityList extends Component {
    constructor(props) {
      super(props);
  
      this.acitivityList = this.acitivityList.bind(this);
  
      this.state = {activities: [], open: false};

      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
    }
    
componentDidMount() {
    axios.get(API_URL+'/activities')
        .then(response => {
        this.setState({ activities: response.data })
        })
        .catch((error) => {
        console.log(error); 
        })
    }

acitivityList() {
    return this.state.activities.map(currentactivity => {
        return <HomePage Activity={currentactivity} showActivity={this.showActivity} key={currentactivity._id}/>;
    })
  }

showModal() {
  document.getElementById("bg").style.filter = "blur(5px)";
  this.setState({open:true})
}

hideModal() {
  document.getElementById("bg").style.filter = "blur(0px)";
  this.setState({open:false})
}


render() {
    return (
      <div>
        <div id="bg">
        <img src={streakMap} width="25%" className="Logo" alt='StreakMap'/>
          <div className='div1'>Nothing can take the place of good old persistence, Want to do something daily and stay consistent?<br/>
              StreakMap helps you maintain a streak for any of your tasks or goals, so that you can get better one day at a time
          </div>
          <div className='div2'>Create an activity you wish to do<br/>
            Come back daily<br/>
            Mark your activity complete<br/> 
            Maintain your streak<br/>
            Get better one day at a time<br/>
            <div className='div3'>
              Stay Motivated!!
            </div>
          </div>
          <div className='div4'>
            Your Activity List
          </div>
          { this.acitivityList() }
          <button className='addAct' onClick={this.showModal}><p className='addActTxt'><img src={plus} width="50%" alt='+'/><br/>Add Activity</p></button>
        </div>
        {this.state.open && <AddActivity hideModal={this.hideModal}/>}
      </div>
    )
  }
}
  
