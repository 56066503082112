//import logo from './logo.svg';
import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import "./App.css"

import AddActivity from "./components/AddActivity.component";
import HomePage from "./components/HomePage.component";
import Activity from './components/Activity.component';

function App(){
 
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/addActivity" element={<AddActivity />} />
        <Route path="/activity" element={<Activity />}/>
      </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App;