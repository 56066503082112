import React, { Component } from "react";
import axios from 'axios';
import { API_URL } from "../variables";

export default class AddActivity extends Component {
    constructor(props) {
        super(props);
        this.onSubmit= this.onSubmit.bind(this)
        this.addActivity= this.addActivity.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.state ={
            title:"",
            description:"",
        }
    }

    addActivity(input){
        this.setState({
            title: input.target.value,
            description:input.taget.value
        });
    }
    onChangeDescription(e) {
      this.setState({
        description: e.target.value
      })
    }
    onChangeTitle(e) {
      this.setState({
        title: e.target.value
      })
    }
    onSubmit(e) {
        e.preventDefault();
    
        const activity = {
          title: this.state.title,
          description: this.state.description
        }
    
        const postData = async () => await axios.post(API_URL+'/activities', activity)
        .then(res => {
          console.log(res.data);
          window.location = '/';
        });  
        postData();
        //window.location = '/';
      }

    render() {
        return (
            <div className="modalContainer">
              <div className="titleCloseBtn">
              <button
                onClick={this.props.hideModal}
              >
              X
              </button>
              </div>
              <h1>Add activity</h1>
              <form onSubmit={this.onSubmit}>
                <label>Title</label>
                <input type="text" required value={this.state.title} onChange={this.onChangeTitle} className="titleInput"></input><br/>
                <label>Description</label>
                <input type="text" required value={this.state.description} onChange={this.onChangeDescription} className="descInput"/>
                <div>
                  <input type="submit" value="Add" className="greenButton"/>
                </div>
              </form>
            </div>
        )
    }
}
