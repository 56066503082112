import React from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router";
import streakMap from "../Assets/StreakMaplogo@2x.png"
import { Link } from 'react-router-dom';
import { API_URL } from '../variables';

export default function Activity(){

    const location= useLocation();
    const navigate= useNavigate();
    const [post, setPost] = React.useState([]);

    React.useEffect(() => {
    axios.get(API_URL+"/activities/"+location.state.id).then((response) => {
      setPost(response.data);
    });
    }, []);

	async function completeActivity(){ 
		await axios.patch(API_URL+"/activity/"+location.state.id);
		axios.get(API_URL+"/activities/"+location.state.id).then((response) => setPost(response.data));		
	}

	function deleteActivity(){
		axios.delete(API_URL+"/activity/"+location.state.id).then(navigate("/"))
	}

	function Visulaize(array1){
		const lastXdays = array1['lastXdays']?array1['lastXdays']:Array.from({length:112},(x,i)=>false)
		var d= Array.from({length:16},(x,i)=><g transform={"translate("+11*i+",0)"} className="react-calendar-heatmap-week" key={""+i}>
		{Array.from({length:7},(k,j)=> lastXdays[(i*7)+j] ?
		<rect width="10" height="10" x="0" y={j*11} rx="0.5" className="color-filled" key={""+x+""+j}><title></title></rect>:<rect width="10" height="10" x="0" y={j*11} rx="0.5" className="color-unfilled" key={""+x+""+j}><title></title></rect>)}
		</g>)
		return (d)
	}

    return (
    <div>
        <img src={streakMap} width="25%" className="Logo" alt='StreakMap'/>
        <div className='divA1'>{post.title}</div>
        <div style={{ width: "50%", margin: "26px", position: "center", float: "left"}}>
			<div className='heatmap-container no-highlight hidden-small'>
				<svg className="react-calendar-heatmap" viewBox="0 0 179 85">
					<g transform="translate(2, 5)" className="react-calendar-heatmap-all-weeks">
						<Visulaize lastXdays={post.lastXdays}/>
					</g>
					<g transform="translate(10, 14)" className="react-calendar-heatmap-weekday-labels"></g>
				</svg>
				<div className="heatmap-overlay">
					<div className="heatmap-text">
						<div className="ui grey statistic">
							<div className="value">{post.streak}</div>
							<div className="label" style={{color: "rgb(118, 118, 118)"}}><span>Streak</span></div>
						</div>
						<div className="ui grey statistic">
							<div className="value">{post.longest}</div>
							<div className="label" style={{color: "rgb(118, 118, 118)"}}><span>Longest</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div className='divA3'>Description</div>
        <div className='divA2'>{post.description}</div>
        <div>
            <button className="greenButton completeBut" onClick={completeActivity}>Complete</button>
        </div>
		<div style={{float:"left"}}>
            <Link to="/"><button className='greyButton'>Back</button></Link>
        </div>
		<div>
            <button className="redButton completeBut" onClick={deleteActivity}>Remove Activity</button>
        </div>
    </div>)
}
